import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/Layout"
import React from "react"
import Seo from "../components/seo"

const MagazinesPage = ({pageContext}) => {

  const {
    breadcrumb: { crumbs },
  } = pageContext;

  let crumbs_mod = crumbs.slice(0);

  crumbs_mod.splice(-1, 0, {pathname: '/media', crumbLabel: 'Media'});

  return (
    <Layout >
      <Seo title="Talman magazine scans" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading">Talman<br /> magazine scans</h1>
        <Breadcrumb crumbs={crumbs_mod} crumbLabel="Magazines" crumbSeparator="»" />
        
        <section className="flex flex-col items-center">
          <h2 className="text-2xl mt-28">coming soon...</h2>
        </section>
        
      </div>
    </Layout>
  )
}

export default MagazinesPage